import React from 'react';
import moment from 'moment-timezone';
import {RouteComponentProps} from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import api from '@api';
import PageWrapper from '../Page/PageWrapper';
import PageTitle from '../Page/PageTitle';
import Section from '../Page/Section';
import Button from '../Page/Button';
import Icon from '../Icon/Icon';
import AddStation from '../Stations/AddStation';
import StationSummary from './StationSummary';
import SalesTagger from '../SalesTagger/SalesTagger';
import TagPopout from '../TagPopout/TagPopout';
import HDOnly from './HDOnly';
import CoLocation from './CoLocation';
import StationAdvocate from '../User/StationAdvocate';
import withPermissions from '../Permissions/withPermissions';
import {RootState} from '@reducers';
import * as actions from '@actions';
import * as selectors from '@selectors';
import './Station.css';

type Props = {
  match: RouteComponentProps<{stationId: string}>['match'];
  history: RouteComponentProps<{stationId: string}>['history'];
  fetchSalesLists: () => void;
} & PropsFromRedux;

type State = {
  stationId: string;
  // station: ConsoleStation | null;
  // isps: StationIsp[];
  stationSalesLists: [];
  activeTag: string | null;
  editing?: boolean;
}

class Station extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props);

    this.state = {
      stationId: props.match.params.stationId,
      stationSalesLists: [],
      activeTag: null,
    };

    this.deleteStation = this.deleteStation.bind(this);
    this.toggleEditing = this.toggleEditing.bind(this);
    this.fetchStation = this.fetchStation.bind(this);
    this.fetchStationISPs = this.fetchStationISPs.bind(this);
    this.addSalesTag = this.addSalesTag.bind(this);
    this.removeSalesTag = this.removeSalesTag.bind(this);
    this.fetchStationLists = this.fetchStationLists.bind(this);
    this.setActiveTag = this.setActiveTag.bind(this);
  }

  componentDidMount () {
    this.fetchStation();
    this.fetchStationISPs();
    this.fetchStationLists();
  }

  setActiveTag (tag: string | null) {
    this.setState({ activeTag: tag });
  }

  toggleEditing () {
    this.setState({ editing: !this.state.editing });
  }

  async fetchStation () {
    const { dispatch } = this.props;
    const { stationId } = this.state;

    const stations = await actions.stations.consoleStations.fetchConsoleStation(dispatch, stationId);
    const station = stations?.[0];

    if (station && station.advocates) {
      const userIds = Object.keys(station.advocates || {});

      await actions.users.fetchUsers(dispatch, userIds);
    }
  }

  fetchStationLists () {
    const { stationId } = this.props.match.params;

    api.request(`sales-lists/stations/${stationId}`)
      .then(res => {
        // @ts-ignore
        this.setState({ stationSalesLists: res.data });
      })
      .catch(err => console.log(err));
  }

  async fetchStationISPs () {
    const { dispatch } = this.props;
    const { stationId } = this.state;

    await actions.stations.stationsIsps.fetchStationsIsps(dispatch, stationId);
  }

  deleteStation () {
    const { match, history, stations } = this.props;
    const { stationId } = match.params;

    const station = stations[parseInt(stationId)];

    if (!station) return;

    // @ts-ignore
    const accepted = window.confirm(`WARNING: You are about to delete station '${station.terminal}', are you sure?`);

    if (accepted) {
      api.deleteStation(stationId)
        .then(() => {
          history.push('/stations');
        })
        .catch(err => {
          console.log(Object.entries(err));
        });
    }
  }

  addSalesTag (tagId: string) {
    const { stationId } = this.props.match.params;

    api.post(`sales-lists/${tagId}/stations`, { stationId })
      .then(data => {
        this.fetchStationLists();
      });
  }

  removeSalesTag (tagId: string) {
    api.deleteRequest(`sales-lists/stations/${tagId}`)
      .then(data => {
        this.fetchStationLists();
      });
  }

  render () {
    const { match, users, history, liveISPList, referrals, stations, stationsIsps } = this.props;
    const { editing } = this.state;
    const { stationId } = match.params;
    const isps = stationsIsps[stationId] ?? [];
    const station = stations[parseInt(stationId)];
    const advocates = station && station.advocates;
    const hasAdvocates = Object.keys(advocates || {}).reduce((acc, userId) => {
      const advocate = advocates?.[userId];

      if (advocate?.isAdvocate === true) {
        return true;
      }

      return acc;
    }, false);

    console.log('Station', {
      stationId,
      station,
      isps,
      advocates,
      hasAdvocates,
    })

    return !!station ? (
      <div className='Station'>
        <PageWrapper>
          <PageTitle
            secondTitle='Station'
            rightContent={
              station
                ? <div className='editButton' onClick={this.toggleEditing}>
                  { editing ? 'STOP EDITING' : 'EDIT' }
                </div>
                : null
            }
          >{ station.terminal }</PageTitle>

          {
            editing
              ? <AddStation edit station={station} />
              : <div>
                <Section>
                  <StationSummary
                    liveISPList={liveISPList}
                    station={station}
                    isps={isps}
                  />
                </Section>

                <Section>
                  <div className='stationDetails'>
                    <Icon name='city' size={40} color='#999' />

                    <div className='detailsBody'>
                      <h2 className='detailsName'>{ station.terminalName }</h2>
                      <div className='address'>
                        { `${station.address1} ${station.city}, ${station.state} ${station.postalCode}` }
                      </div>
                    </div>

                    <StationAdvocate
                      isAdvocate={hasAdvocates}
                      station={station}
                      editable={false}
                    />

                    <CoLocation
                      station={station}
                      fetchStation={this.fetchStation}
                    />

                    <HDOnly
                      station={station}
                      fetchStation={this.fetchStation}
                    />

                    <p className='division'>{station.division2} / {station.region4}</p>
                  </div>
                </Section>

                {
                  hasAdvocates && !!users &&
                  <Section title={'Advocates'}>
                    {
                      Object.keys(station.advocates || {}).map(userId => {
                        const user = users[userId];

                        if (!user) return null;

                        const referral = referrals && referrals[user.inviteCode];
                        const isps = Object.keys(user.ISPS || {}).map(ISPID => {
                          const profile = liveISPList && liveISPList[ISPID];

                          return profile;
                        }).filter(profile => !!profile);

                        return (
                          <div
                            className='UserAdvocate'
                            onClick={() => history.push(`/users/${user.FedExId}`)}>
                            <div className='title'>
                              <div className='iconWrap'>
                                <Icon name='account' size={30} color='#006DAB' />
                              </div>

                              <p>{user.first} {user.last}</p>

                              {
                                isps.map(profile => {
                                  return (
                                    <p
                                      style={{ fontSize: '10px', padding: '5px' }}>
                                      {profile.CSPName}
                                    </p>
                                  );
                                })
                              }
                            </div>

                            <StationAdvocate
                              isAdvocate
                              editable={false}
                              station={station}
                            />

                            <p
                              style={{ color: '#168E32', fontSize: '12px' }}>
                              {Object.keys((referral && referral.pendingInvites) || {}).length} PENDING
                            </p>

                            <p style={{ color: '#006DAB', fontSize: '12px', marginLeft: '5px' }}>
                              {Object.keys((referral && referral.completedInvites) || {}).length} COMPLETE
                            </p>
                          </div>
                        );
                      })
                    }
                  </Section>
                }

                {
                  station.sortTime || station.flexTime || station.dispatchTime
                    ? <Section>
                      <div className='timeline'>
                        {
                          station.flexTime
                            ? <div className='timeline-tick'>
                              <div>
                                <h5>Flex Time</h5>
                              </div>

                              <div>
                                <p>{moment(station.flexTime).format('h:mm a')}</p>
                              </div>
                            </div>
                            : null
                        }

                        {
                          station.flexTime
                            ? <Icon name='arrow-right-bold' color='#999' size={50} />
                            : null
                        }

                        {
                          station.sortTime
                            ? <div className='timeline-tick'>
                              <div>
                                <h5>Sort Time</h5>
                              </div>

                              <div>
                                <p>{moment(station.sortTime).format('h:mm a')}</p>
                              </div>
                            </div>
                            : null
                        }

                        {
                          station.sortTime
                            ? <Icon name='arrow-right-bold' color='#999' size={50} />
                            : null
                        }

                        {
                          station.dispatchTime
                            ? <div className='timeline-tick'>
                              <div>
                                <h5>Dispatch Time</h5>
                              </div>

                              <div>
                                <p>{moment(station.dispatchTime).format('h:mm a')}</p>
                              </div>
                            </div>
                            : null
                        }

                      </div>
                    </Section>
                    : null
                }

                <Section>
                  <SalesTagger
                    tags={this.props.salesLists}
                    activeTags={this.state.stationSalesLists}
                    addSalesTag={this.addSalesTag}
                    removeSalesTag={this.removeSalesTag}
                    setActiveTag={this.setActiveTag}
                  />
                </Section>

                {
                  this.state.activeTag
                    ? <TagPopout
                      tag={this.state.activeTag}
                      item={station}
                      type='stations'
                      setActiveTag={this.setActiveTag}
                      updateProps={this.props.fetchSalesLists}
                    />
                    : null
                }

                <Button color='#B90C0F' onClick={this.deleteStation}>
                  Delete Station
                </Button>
              </div>
          }
        </PageWrapper>
      </div>
    ) : <NotFound stationId={stationId} />;
  }
}

function NotFound ({ stationId }: { stationId: string }) {
  return (
    <div className='Station'>
      <PageWrapper>
        <PageTitle secondTitle='Station'>{ stationId }</PageTitle>

        <Section>
          <div className='notFound'>
            <Icon size={150} color='#B90C0F' name='cancel' />

            <h1>No station with id '<span>{stationId}</span>' found</h1>
          </div>
        </Section>
      </PageWrapper>
    </div>
  );
}

function mapStateToProps (state: RootState) {

  return {
    liveISPList: selectors.liveIspList.selectLiveIspList(state),
    collections: selectors.salesCampaignCollections.selectSalesCampaignCollections(state),
    consoleStations: selectors.stations.consoleStations.selectConsoleStations(state),
    stationsIsps: selectors.stations.stationsIsps.selectStationIspsLists(state),
    referrals: selectors.referrals.selectReferrals(state),
    users: selectors.users.selectUsers(state),
    salesLists: selectors.salesLists.selectSalesLists(state),
    stations: selectors.stations.consoleStations.selectConsoleStationsBySqlId(state),
  };
}

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(withPermissions(Station));
