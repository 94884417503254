import React from 'react';
import './ListOptions.css';

export default function ListOptions (props) {
  return (
    <div
      className='PipelineListOptions'
      style={{ left: `(props.left * 20)%` }}>
      <div className='title'>
        <h3>List Actions</h3>
      </div>
    </div>
  );
}
