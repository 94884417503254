import React, { Component } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  RouteComponentProps
} from 'react-router-dom';
import firebaseProvider, {FirebaseUser} from '@utils/firebase';
import { connect, ConnectedProps } from 'react-redux';
import NoPermission from './components/Permissions/NoPermission';
import Nav from './components/Nav/Nav';
import SalesLists from './components/SalesLists/SalesLists';
import Dashboard from './components/Dashboard/Dashboard';
import Stations from './components/Stations/Stations';
import Station from './components/Station/Station';
import Isp from './components/Isp/Isp';
import Login from './components/Login/Login';
import ISPsDash from './components/ISPsDash/ISPsDash';
import UsersDash from './components/UsersDash/UsersDash';
import User from './components/User/User';
import SalesPipeline from './components/SalesPipeline/SalesPipeline';
import Signups from './components/Signups/Signups';
import Knowledge from './components/Knowledge/Knowledge';
import ServiceStatus from './components/ServiceStatus/ServiceStatus';
import GhostAccounts from './components/GhostAccounts/GhostAccounts';
import ServiceManagement from './components/ServiceManagement/ServiceManagement';
import {CustomersAtRiskPage} from '@components/CustomersAtRisk/CustomersAtRiskPage';
import FeatureFlags from './components/FeatureFlags/FeatureFlags';
import {AppThunkDispatch} from '@store';
import { RootState } from '@reducers';
import { Referrals } from '@reducers/referrals';
import { Stations as StationsWithAdvocates } from '@reducers/stations/productStations';
import * as actions from '@actions';
import * as selectors from '@selectors';
import { updateViewport } from '@reducers/viewport';
import api from './utils/api';
import SubscriptionsPage from './components/Payment/SubscriptionsPage.js';
import bugsnagClient from '@utils/bugsnag';
import PageSpinner from '@components/Page/PageSpinner';
import { getDaysAgo } from '@utils/dates';
import './App.css';
import { PermissionSetKey } from './components/Permissions/withPermissions';

const LAST_THIRTY_DAYS = 30;

type Props = {} & PropsFromRedux;

type State = {
  partials: Record<string, any>;
  manualManifestList: Record<string, any>;
  loadingData: boolean;
}

class App extends Component<Props, State> {
  router: RouteComponentProps | null;

  constructor (props: Props) {
    super(props);

    this.state = {
      partials: {},
      manualManifestList: {},
      loadingData: false,
    };

    this.router = null;
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.fetchSalesLists = this.fetchSalesLists.bind(this);
    this.fetchTotals = this.fetchTotals.bind(this);
    this.fetchUsage = this.fetchUsage.bind(this);
    this.fetchActivity = this.fetchActivity.bind(this);
    this.setActivityListener = this.setActivityListener.bind(this);
    this.setProfilesListener = this.setProfilesListener.bind(this);
    this.setUsageListener = this.setUsageListener.bind(this);
    this.onAuthStateChanged = this.onAuthStateChanged.bind(this);
    this.setPartialsListener = this.setPartialsListener.bind(this);
    this.setSignupsListener = this.setSignupsListener.bind(this);
    this.fetchSignupsForDates = this.fetchSignupsForDates.bind(this);
    this.fetchActiveStations = this.fetchActiveStations.bind(this);
    this.fetchConsoleUsers = this.fetchConsoleUsers.bind(this);
    this.setCollectionsListener = this.setCollectionsListener.bind(this);
    this.fetchUsers = this.fetchUsers.bind(this);
    this.fetchManualManifestList = this.fetchManualManifestList.bind(this);
    this.loadReferrals = this.loadReferrals.bind(this);
    this.fetchStationsWithAdvocates = this.fetchStationsWithAdvocates.bind(this);
    this.handleBrowserResize = this.handleBrowserResize.bind(this);
  }

  async componentDidMount () {
    firebaseProvider.getConsoleAuth().onAuthStateChanged(this.onAuthStateChanged);

    this.subscribeBrowserViewport();
  }

  componentWillUnmount () {
    this.unsubscribeBrowserViewport();

    const consoleDB = firebaseProvider.getConsoleDefaultDB();

    consoleDB.ref('partials/data').off();
  }

  onAuthStateChanged (user: FirebaseUser | null) {
    if (!user) {
      this.logout();
    }
    else {
      this.login(user);
    }
  }

  async login (user: FirebaseUser) {
    const { dispatch } = this.props;

    actions.firebaseUser.setFirebaseUser(dispatch, user);

    if (this.router?.history?.location.pathname === '/login') {
      this.router.history.push('/');
    }

    await this.fetchConsoleUsers();
    await this.fetchPermissions();

    this.initializeBugsnag(user);

    await this.loadDatasets();
  }

  async loadDatasets () {
    this.setState({ loadingData: true });

    try {
      await Promise.all([
        this.fetchSalesLists(),
        this.fetchTotals(),
        this.fetchUsage(),
        this.fetchActivity(),
        this.setActivityListener(),
        this.fetchDailyActiveUsers(),
        this.setDailyActiveUsersListener(),
        this.setProfilesListener(),
        this.setUsageListener(),
        this.setSignupsListener(),
        this.setPartialsListener(),
        this.fetchSignupsForDates(),
        this.fetchActiveStations(),
        this.setCollectionsListener(),
        this.fetchStripeSubscriptionCategories(),
        this.fetchManualManifestList(),
        this.loadReferrals(),
        this.fetchStationsWithAdvocates(),
        this.fetchCancellationReasons(),
      ]);
    } catch (err) {
      console.error(err);
      bugsnagClient.notify(err as Error);
    }

    this.setState({ loadingData: false })
  }

  initializeBugsnag (user: FirebaseUser) {
    bugsnagClient.setUser(
      user.uid,
      user.email ?? 'no email provided',
      user.displayName ?? 'unknown user name',
    );
  }

  subscribeBrowserViewport () {
    this.handleBrowserResize();

    window.addEventListener('resize', this.handleBrowserResize)
  }

  unsubscribeBrowserViewport () {
    window.removeEventListener('resize', this.handleBrowserResize);
  }

  handleBrowserResize () {
    if (document?.body) {
      const width = document.body.clientWidth;
      const height = document.body.clientHeight;

      return this.props.dispatch(updateViewport({ height, width }));
    }
  }

  async loadReferrals () {
    const { dispatch } = this.props;

    const referrals = await actions.referrals.fetchReferrals(dispatch);
    const userIds = this.getReferrerIdsFromReferrals(referrals);

    this.fetchUsers(userIds);
  }

  getReferrerIdsFromReferrals (referrals: Referrals): string[] {
    const userIds: string[] = [];

    for (const inviteCode in referrals) {
      const referral = referrals[inviteCode];

      if (referral.uid && !userIds.includes(referral.uid)) {
        userIds.push(referral.uid);
      }

      if (referral.pendingInvites) {
        for (const uid in referral.pendingInvites) {
          if (uid && !userIds.includes(uid)) {
            userIds.push(uid);
          }
        }
      }

      if (referral.completedInvites) {
        for (const uid in referral.completedInvites) {
          if (uid && !userIds.includes(uid)) {
            userIds.push(uid);
          }
        }
      }
    }

    return userIds;
  }

  async fetchStripeSubscriptionCategories () {
    const { dispatch } = this.props;

    await Promise.all([
      actions.subscriptions.canceled.fetchCanceledSubscriptions(dispatch),
      actions.subscriptions.cancellingAtPeriodEnd.fetchCancellingAtPeriodEndSubscriptions(dispatch),
      actions.subscriptions.converted.fetchTrialSubscriptions(dispatch),
      actions.subscriptions.pastDue.fetchPastDueSubscriptions(dispatch),
      actions.subscriptions.trials.fetchTrialSubscriptions(dispatch),
    ]);
  }

  async fetchUsers (userIds: string[]) {
    const { dispatch } = this.props;

    await actions.users.fetchUsers(dispatch, userIds);
  }

  async logout () {
    try {
      const { dispatch } = this.props;

      this.clearAppStateStore();

      actions.unsubscribeAndResetAppUserData(dispatch);

      await firebaseProvider.getConsoleAuth().signOut();

      this.router?.history.push('/login');
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Clears redux state store on logout
   */
  clearAppStateStore () {
    const {dispatch} = this.props;

    actions.unsubscribeAndResetAppData(dispatch);
  }

  async fetchPermissions () {
    const { dispatch } = this.props;

    await actions.permissions.fetchPermissions(dispatch);
  }

  async setCollectionsListener () {
    const { dispatch } = this.props;

    await actions.salesCampaignCollections.subscribeSalesCampaignCollections(dispatch);
  }

  async fetchCancellationReasons () {
    const { dispatch } = this.props;

    await actions.cancellationReasons.fetchCancellationReasons(dispatch);
  }

  async fetchSignupsForDates () {
    const { dispatch, timeZone } = this.props;

    const lastThirtyDays = getDaysAgo(LAST_THIRTY_DAYS, timeZone);

    const thunkDispatch = dispatch as AppThunkDispatch;

    await thunkDispatch(actions.signups.fetchPreviousDaysSignups(lastThirtyDays));
  }

  async setSignupsListener () {
    const { dispatch } = this.props;

    await actions.signups.subscribeTodaysSignups(dispatch);
  }

  async setPartialsListener () {
    return new Promise((resolve, reject) => {
      try {
        const consoleDB = firebaseProvider.getConsoleDefaultDB();
        const PartialsRef = consoleDB.ref('partials/data');

        PartialsRef.on('value', snap => {
          if (!snap.val()) resolve({});

          this.setState({ partials: snap.val() });

          resolve(snap.val());
        });
      } catch (err) {
        console.error(err);
        bugsnagClient.notify(err as Error);

        reject(err);
      }
    });
  }

  async setUsageListener () {
    const { dispatch } = this.props;

    await actions.metrics.dailyOperationsUsage.subscribeTodaysUsage(dispatch);
    await actions.metrics.dailyOperationsUsage.subscribeUsageUpdatedAt(dispatch);
  }

  async fetchUsage () {
    const { dispatch, timeZone } = this.props;

    const lastThirtyDays = getDaysAgo(LAST_THIRTY_DAYS, timeZone);

    const thunkDispatch = dispatch as AppThunkDispatch;

    await thunkDispatch(actions.metrics.dailyOperationsUsage.fetchPreviousDaysUsage(lastThirtyDays));
    await actions.metrics.dailyOperationsUsage.fetchUsageUpdatedAt(dispatch);
  }

  async setDailyActiveUsersListener () {
    const { dispatch } = this.props;

    await actions.metrics.dailyActiveUsers.subscribeTodaysDailyActiveUsers(dispatch);
    await actions.metrics.dailyActiveUsers.subscribeDailyActiveUsersUpdatedAt(dispatch);
  }

  async fetchDailyActiveUsers () {
    const { dispatch, timeZone } = this.props;

    const lastThirtyDays = getDaysAgo(LAST_THIRTY_DAYS, timeZone);

    const thunkDispatch = dispatch as AppThunkDispatch;

    await thunkDispatch(actions.metrics.dailyActiveUsers.fetchPreviousDaysDailyActiveUsers(lastThirtyDays));
    await actions.metrics.dailyActiveUsers.fetchDailyActiveUsersUpdatedAt(dispatch);
  }

  async setActivityListener () {
    const {dispatch} = this.props;

    await actions.metrics.dailyOperationsActivity.subscribeTodaysActivity(dispatch);
    await actions.metrics.dailyOperationsActivity.subscribeActivityUpdatedAt(dispatch);
  }

  async setProfilesListener () {
    const {dispatch} = this.props;

    await actions.liveIspList.subscribeLiveIspList(dispatch);
  }


  async fetchActivity () {
    const {dispatch, timeZone } = this.props;

    const lastThirtyDays = getDaysAgo(LAST_THIRTY_DAYS, timeZone);

    const thunkDispatch = dispatch as AppThunkDispatch;

    await thunkDispatch(actions.metrics.dailyOperationsActivity.fetchPreviousDaysActivity(lastThirtyDays))
    await actions.metrics.dailyOperationsActivity.fetchActivityUpdatedAt(dispatch);
  }

  async fetchTotals () {
    const {dispatch} = this.props;

    await actions.metrics.totalOperationsAndStations.fetchTotalOperationsAndStations(dispatch);
  }

  async fetchSalesLists () {
    const {dispatch} = this.props;

    await actions.salesLists.fetchSalesLists(dispatch);
  }

  async fetchActiveStations () {
    const { dispatch } = this.props;

    await actions.stations.activeStations.fetchActiveStations(dispatch);
  }

  async fetchConsoleUsers () {
    const { dispatch } = this.props;

    await actions.consoleUsers.fetchConsoleUsers(dispatch);
  }

  fetchManualManifestList () {
    api.request<{}>(`tasks/disabled/manifest`)
       .then(res => {
         this.setState(prevState => {
            return {
              ...prevState,
              manualManifestList: res.data || {},
            };
         });
       })
       .catch(err => console.log(err))
  }

  async fetchStationsWithAdvocates () {
    const { dispatch } = this.props;

    const stations = await actions.stations.productStations.fetchStationsWithAdvocates(dispatch);

    const advocateUserIds = this.getStationAdvocatesFromStationsWithAdvocates(stations);

    await this.fetchUsers(advocateUserIds);
  }

  getStationAdvocatesFromStationsWithAdvocates (stations: StationsWithAdvocates) {
    const advocateUserIds = [];

    for (const stationId in stations) {
      const station = stations[stationId];
      const advocates = station.advocates || {};

      for (const uid in advocates) {
        advocateUserIds.push(uid);
      }
    }

    return advocateUserIds;
  }

  render () {
    const { firebaseUser, permissions, consoleUser } = this.props;
    // const { loadingData } = this.state;

    const checkingPermissions = firebaseUser && (!permissions || !consoleUser);

    return (
      <div className='App'>
        <BrowserRouter ref={ref => {
          // @ts-ignore
          this.router = ref || null;
        }}>
          <div id='page'>
            {
              !checkingPermissions &&
                <Nav logout={this.logout} />
            }

            <div id='page-body'>
              <Switch>
                {(checkingPermissions /* || loadingData */) &&
                  <Route
                    path='/'
                    render={() => {
                      return (
                        <PageSpinner
                          label={checkingPermissions ? 'Checking permissions...' : 'Loading...'}
                        />
                      )
                    }}
                  />
                }

                <Route
                  exact
                  path='/'
                  render={(_routerProps) => {
                    return (
                      <Dashboard
                        permissionsSetKey={PermissionSetKey.DASHBOARD}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path='/stations'
                  render={(routerProps) => {
                    return (
                      <Stations
                        history={routerProps.history}
                        permissionsSetKey={PermissionSetKey.STATIONS_DASH}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path='/stations/:stationId'
                  render={(routerProps) => {
                    return (
                      <Station
                        match={routerProps.match}
                        history={routerProps.history}
                        permissionsSetKey={PermissionSetKey.STATION}
                        fetchSalesLists={this.fetchSalesLists}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path='/isps'
                  render={(_) => {
                    return (
                      <ISPsDash
                        permissionsSetKey={PermissionSetKey.ISPS_DASHBOARD}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path='/users'
                  render={(routerProps) => {
                    return <UsersDash history={routerProps.history} />;
                  }}
                />

                <Route
                  exact
                  path='/users/:fedExId'
                  render={(routerProps) => {
                    return (
                      <User
                        {...routerProps}
                        activeStations={this.props.activeStations}
                        fetchActiveStations={this.fetchActiveStations}
                        fetchUsers={this.fetchUsers}
                        liveISPList={this.props.liveISPList}
                        permissionsSetKey={PermissionSetKey.USERS_DASH}
                        users={this.props.users}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path='/signups'
                  render={(routerProps) => {
                    return (
                      <Signups
                        permissionsSetKey={PermissionSetKey.SIGNUPS}
                        fetchUsers={this.fetchUsers}
                        history={routerProps.history}
                        partials={this.state.partials}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path='/sales'
                  render={(routerProps) => {
                    return (
                      <SalesLists
                        {...routerProps}
                        permissionsSetKey={PermissionSetKey.SALES_LISTS}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path='/pipeline'
                  render={(routerProps) => {
                    return (
                      <SalesPipeline
                        {...routerProps}
                        user={firebaseUser}
                        permissionsSetKey={PermissionSetKey.PIPELINE}
                        activity={this.props.dailyOperationsActivity}
                        usage={this.props.dailyOperationsUsage}
                        liveISPList={this.props.liveISPList}
                        salesLists={this.props.salesLists}
                        collections={this.props.salesCampaignCollections}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path='/isps/:id'
                  render={(routerProps) => {
                    return (
                      <Isp
                        {...routerProps}
                        consoleUser={consoleUser!}
                        permissionsSetKey={PermissionSetKey.ISP}
                        usage={this.props.dailyOperationsUsage}
                        salesLists={this.props.salesLists}
                        fetchSalesLists={this.fetchSalesLists}
                        fetchManualManifestList={this.fetchManualManifestList}
                        liveISPList={this.props.liveISPList}
                      />
                    );
                  }}
                />

                <Route
                  path='/knowledge'
                  render={(routerProps) => {
                    return (
                      <Knowledge
                        permissionsSetKey={PermissionSetKey.KNOWLEDGE}
                        {...routerProps}
                      />
                    );
                  }}
                />

                <Route
                  path='/ghostaccounts'
                  render={(routerProps) => {
                    return (
                      <GhostAccounts
                        user={firebaseUser}
                        permissionsSetKey='ghost-accounts'
                        {...routerProps}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path='/login'
                  render={() => (
                    <Login login={this.login} />
                  )}
                />

                <Route
                  path='/subscriptions'
                  render={(_routerProps) => {
                    return (
                      <SubscriptionsPage
                        permissionsSetKey={PermissionSetKey.SUBSCRIPTIONS}
                      />
                    );
                  }}
                />

                <Route
                  path='/service_status'
                  render={(_routerProps) => {
                    return (
                      <ServiceStatus
                        permissionsSetKey={PermissionSetKey.SERVICE_STATUS}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path='/service_management'
                  render={(routerProps) => {
                    return (
                      <ServiceManagement
                        permissionsSetKey={PermissionSetKey.SERVICE_MANAGEMENT}
                        history={routerProps.history}
                        manualManifestList={this.state.manualManifestList}
                        fetchManualManifestList={this.fetchManualManifestList}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path='/feature_flags'
                  render={(routerProps) => {
                    return (
                      <FeatureFlags
                        permissionsSetKey={PermissionSetKey.FEATURE_FLAGS}
                        liveISPList={this.props.liveISPList}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path='/prohibited'
                  render={(_routerProps) => {
                    return <NoPermission />;
                  }}
                />

                <Route
                  exact
                  path='/customers_at_risk'
                  render={(_routerProps) => {
                    return <CustomersAtRiskPage />;
                  }}
                />
              </Switch>
            </div>
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

function mapStateToProps (state: RootState) {

  return {
    activeStations: selectors.stations.activeStations.selectActiveStations(state),
    consoleUser: selectors.consoleUsers.selectConsoleUser(state),
    dailyOperationsActivity: selectors.metrics.dailyOperationsActivity.selectDailyOperationsActivity(state),
    dailyOperationsUsage: selectors.metrics.dailyOperationsUsage.selectDailyOperationsUsage(state),
    dailyOperationsUsageUpdatedAt: selectors.metrics.dailyOperationsUsage.selectDailyOperationsUsageUpdatedAt(state),
    firebaseUser: selectors.firebaseUser.selectFirebaseUser(state),
    liveISPList: selectors.liveIspList.selectLiveIspList(state),
    permissions: selectors.permissions.selectPermissions(state),
    salesCampaignCollections: selectors.salesCampaignCollections.selectSalesCampaignCollections(state),
    salesLists: selectors.salesLists.selectSalesLists(state),
    timeZone: state.timeZone.data,
    totalOperationsAndStationsMetric: selectors.metrics.totalOperationsAndStation.selectTotalOperationsAndStations(state),
    users: selectors.users.selectUsers(state),
  };
}

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(App);
