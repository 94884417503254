import React from 'react';
import moment from 'moment-timezone';
import { connect, ConnectedProps } from 'react-redux';
import pr from '@packageroute/types-firebase';
import Modal from '../Page/Modal'
import Icon from '../Icon/Icon';
import api, { ISPNote } from '../../utils/api';
import Stripe from 'stripe';
import DownloadHistory from '../DownloadHistory/DownloadHistory';
import Note from '../Note/Note';
import SearchInput from '../Search/SearchInput';
import IspEntry from '../Search/IspEntry';
import SearchResults from '../Search/SearchResults';
import FirebaseTextLink from '../common/FirebaseTextLink';
import StripeLink from '../common/StripeLink';
import ConsoleLink from '../common/ConsoleLink';
import {RootState} from '@reducers';
import {LiveIspProfile} from '@reducers/liveIspList';
import * as selectors from '@selectors';
import './ISPModal.css';


type User = pr.users.User;

type PrimaryContact = {
  id: number;
  firstName: string;
  lastName: string;
  phone: string;
  secondaryPhone: string;
  secondaryEmail: string;
  role: number;
  preferredTime: string;
  preferredMethod: string;
  appleId: string;
  playId: string;
  owner: boolean;
  primary: boolean;
}

type ConsoleISP = {
  additionalRoutes: number | null;
  churned: string | null;
  city: string;
  entityId: string;
  entityName: string;
  firebaseId: string;
  groundRoutes: number;
  hdRoutes: number;
  id: number;
  initialContact: string;
  lead: string | null;
  notInterested: string | null;
  numEntities: number;
  projectedClose: string;
  projectedConversionRate: number;
  qualified: string;
  qualifiedDescription: string;
  referred: string;
  referredDescription: string;
  signup: string;
  state: string;
  suppRoutes: number;
  timeZone: string;
  trial: string;
  createdAt: string;
  updatedAt: string;
}

type Props = {
  setActiveItem: (a: string | null, b?: string | null, c?: string | null) => void;
  profile: LiveIspProfile;
  list?: any;
  tag?: any;
} & PropsFromRedux;

type State = {
  isp: null | ConsoleISP;
  fetchingISP: boolean;
  downloadDates: string[];
  primaryContact: User | PrimaryContact | null;
  results: null;
  search: false;
  searchTimeout: NodeJS.Timeout | null;
  searchText: string;
  selectedISP: null;
  associationMade: boolean;
  searching?: boolean;
  notes?: ISPNote[];
  owner: User | null;
  subscription?: Stripe.subscriptions.ISubscription;
  showDeletionModal: boolean;
}

class ISPModal extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props);

    const downloadDates = [];
    // const { profile } = this.props;

    for (let i = 6; i >= 0; i--) {
      downloadDates.push(moment.tz('America/Los_Angeles').subtract(i, 'days').format('YYYY-MM-DD'));
    }

    this.state = {
      isp: null,
      downloadDates,
      primaryContact: null,
      results: null,
      fetchingISP: false,
      search: false,
      searchTimeout: null,
      searchText: "",
      selectedISP: null,
      associationMade: true,
      owner: null,
      showDeletionModal: false,
    };

    this.deleteItem = this.deleteItem.bind(this);
    this.fetchISP = this.fetchISP.bind(this);
    this.onChange = this.onChange.bind(this);
    this.search = this.search.bind(this);
    this.selectISP = this.selectISP.bind(this);
    this.associateISP = this.associateISP.bind(this);
    this.fetchOwner = this.fetchOwner.bind(this);
    this.fetchPrimaryContact = this.fetchPrimaryContact.bind(this);
    this.fetchSubscription = this.fetchSubscription.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  async componentDidMount () {
    const { profile } = this.props;

    document.addEventListener('keyup', this.handleKeyPress);
    
    const isp = await this.fetchISP();

    this.fetchSubscription();

    if (!isp) {
      const { ownerUID } = (profile || {});

      api.getUserData(ownerUID)
        .then(primaryContact => {
          this.setState({ primaryContact, associationMade: false });
        })
        .catch(err => {
          this.setState({ associationMade: false });
        })
      ;
    }
    else {
      const notes = await api.getISPNotes(isp.id);

      this.setState({ isp: isp, notes }, () => {
        this.fetchOwner();
        this.fetchPrimaryContact();
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyPress);
  }

  async componentDidUpdate () {
    const { liveISPList, profile } = this.props;
    const { isp, fetchingISP, associationMade } = this.state;
    if (
      !isp && 
      liveISPList && 
      profile.ISPID && 
      !fetchingISP && 
      !associationMade
    ) {
      const isp = await this.fetchISP();

      if (!isp) {
        this.setState({ associationMade: false });
      }
      else {
        const notes = await api.getISPNotes(isp.id);

        this.setState({ isp: isp, notes }, () => {
          this.fetchOwner();
          this.fetchPrimaryContact();
        });
      }
    }
  }

  handleKeyPress ({ keyCode }: { keyCode: string | number }) {
    if (keyCode == 27 || keyCode == '27') {
      this.props.setActiveItem(null, null) // esc
    }
  }

  onChange ({ target }: React.ChangeEvent<HTMLInputElement>) {
    const { searchTimeout } = this.state;

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const timeoutID = setTimeout(this.search, 1000);

    this.setState({
      searchTimeout: timeoutID,
      // @ts-ignore
      results: {},
      selectedISP: null,
      searchText: target.value,
      searching: true
    });
  }

  fetchOwner () {
    if (!this.props.profile || !this.props.profile.ownerUID) return;

    api.request<User>(`contacts/owner/${this.props.profile.ownerUID}`)
      .then(res => {
        this.setState({ owner: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  }

  async fetchSubscription () {
    try {
      if (!this.props.profile) return;

      const res = await api.request<Stripe.subscriptions.ISubscription>(`subscriptions/${this.props.profile.ISPID}`);

      this.setState({ subscription: res.data });
    }
    catch (err) {
      console.log(err);
    }
  }

  selectISP (isp: any) {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    this.setState({ selectedISP: isp, searching: false, results: null, searchText: '', searchTimeout: null });
  }

  async search () {
    try {
      const { searchText } = this.state;

      if (!searchText || searchText.length < 3) return;

      const res = await api.request(`search/isps/${searchText}`);
      
      // @ts-ignore
      this.setState({ results: res.data, searching: false });
    }
    catch(err) {
      console.error(err);
      this.setState({ searching: false });
    }
  }

  associateISP () {
    const { profile } = this.props;
    const { selectedISP } = this.state;

    // @ts-ignore
    api.patch(`isps/${selectedISP.id}`, { firebaseId: profile.ISPID })
      .then(async res => {
        const isp = res.data[0];

        const notes = await api.getISPNotes(isp.id);

        this.setState({ isp, notes, associationMade: true }, () => {
          this.fetchOwner();
          this.fetchPrimaryContact();
        });
      });
  }

  async fetchPrimaryContact () {
    const { isp } = this.state;

    if (!isp) return;

    const res = await api.request<PrimaryContact>(`contacts/isps/primary/${isp.id}`);

    this.setState({ primaryContact: res.data });
  }

  async fetchISP () {
    this.setState({ fetchingISP: true });
    const { profile } = this.props;

    if (!profile || !profile.ISPID) {
      this.setState({ fetchingISP: false });

      return null;
    }

    const res = await api.request<ConsoleISP>(`isps/firebase-id/${profile.ISPID}`);

    return res.data;
  }

  deleteItem () {
    const { list } = this.props;
    const { isp } = this.state;

    if (!list || !isp || !isp.id) return;

    api.deleteRequest(`sales-lists/isps/${isp.id}`)
      .then(data => {
        // this will trigger a componentDidUpdate in List
        this.props.setActiveItem(null, null);
      });
  }

  render () {
    const {
      searchText,
      owner,
      primaryContact,
      subscription
    } = this.state;
    const { list, tag, profile, liveISPList, featureFlags } = this.props;
    const isp = this.state.isp;
    const name = (profile && profile.CSPName) 
      ? profile.CSPName
      : isp?.entityName ?? 'No CSP Name';
      const city = (profile && profile.City) 
      ? profile.City 
      : isp?.city ?? 'No City';
      const state = (profile && profile.State) 
      ? profile.State 
      : isp?.state ?? 'No State';
    const stationID = profile && profile.station ? profile.station.stationID : null;

    return (
      <Modal onBackClick={() => this.props.setActiveItem(null, null)}>
        <div className='ISPModal'>
          <div className='top'>
            <div className='name'>
              <p>
                {name}
                <span style={{ marginLeft: '5px' }}>
                  {city}
                  {city && state ? ' ' : ''}
                  {state}
                </span>
              </p>
            </div>

            <div className='close' onClick={() => this.props.setActiveItem(null, null)}>
              <Icon name='close' size={25} color='#666' />
            </div>
          </div>

          <div className='section'>
            <div className='info'>
              <div className='scrollable'>
                <div className='info-section'>
                  <div className='info-header'>
                    <h3>Profile</h3>
                  </div>

                  {
                    stationID
                      ? <div className='info-bit'>
                        <Icon name='domain' size={15} color='black' />
                        <p>{stationID}</p>
                      </div>
                      : null
                  }

                  {
                    profile && profile.WorkAreas
                      ? <div className='info-bit'>
                        <Icon name='map' size={15} color='black' />

                        <p>
                          {
                            profile.WorkAreas
                              ? Object.keys(profile.WorkAreas).length
                              : 0
                          }
                          {` work area${Object.keys(profile.WorkAreas).length > 1 ? 's' : ''} added.`}
                        </p>
                      </div>
                      : null
                  }

                  {
                    profile && profile.drivers
                      ? <div className='info-bit'>
                        <Icon name='account-plus' size={15} color='black' />

                        <p>
                          {
                            profile.drivers
                              ? Object.keys(profile.drivers).length
                              : 0
                          }
                          {` driver${Object.keys(profile.drivers).length > 1 ? 's' : ''} added.`}
                        </p>
                      </div>
                      : null
                  }

                  {
                    profile && profile.authorizedUsersCount
                      ? <div className='info-bit'>
                        <Icon name='account-check' size={15} color='black' />

                        <p>
                          {
                            profile.authorizedUsersCount
                              ? profile.authorizedUsersCount
                              : 0
                          }
                          {` user${profile.authorizedUsersCount > 1 ? 's' : ''} authorized.`}
                        </p>
                      </div>
                      : null
                  }

                  {
                    owner && (owner.first || owner.last)
                      ? <div className='info-bit'>
                        <Icon name='account' size={15} color='black' />

                        <p className='description'>
                          {owner.first}
                          {owner.first && owner.last ? ' ' : ''}
                          {owner.last}
                        </p>
                      </div>
                      : null
                  }

                  {
                    owner && owner.email
                      ? <div className='info-bit'>
                        <Icon name='email' size={15} color='black' />

                        <p className='description'>
                          {owner.email}
                        </p>
                      </div>
                      : null
                  }

                  {
                    owner && owner.phone
                      ? <div className='info-bit'>
                        <Icon name='phone' size={15} color='black' />

                        <p className='description'>
                          {owner.phone}
                        </p>
                      </div>
                      : null
                  }
                </div>

                <div className='info-section section-margin'>
                  <div className='info-header'>
                    <h3>Primary Contact</h3>
                  </div>

                  {
                    primaryContact && primaryContact
                      ? <React.Fragment>
                        { // @ts-ignore
                          primaryContact.firstName || primaryContact.lastName || primaryContact.first || primaryContact.last
                            ? <div className='info-bit'>
                              <Icon name='account' size={15} color='black' />
                              {/* @ts-ignore */}
                              <p>{primaryContact.firstName || primaryContact.first} {primaryContact.lastName || primaryContact.last}</p>
                            </div>
                            : null
                        }

                        {
                          primaryContact.phone
                            ? <div className='info-bit'>
                              <Icon name='phone' size={15} color='black' />
                              <p>{primaryContact.phone}</p>
                            </div>
                            : null
                        }

                        {// @ts-ignore
                          primaryContact.email
                            ? <div className='info-bit'>
                              <Icon name='email' size={15} color='black' />
                              {/* @ts-ignore */}
                              <p>{primaryContact.email}</p>
                            </div>
                            : null
                        }
                      </React.Fragment>
                      : <div>
                        <p>No primary contact.</p>
                      </div>
                  }
                </div>

                {
                  tag && (tag.dueDate || tag.reason)
                    ? <div className='info-section section-margin'>
                      <div className='info-header'>
                        <h3>Details</h3>
                      </div>

                      {
                        tag.dueDate
                          ? <div className='info-bit'>
                            <Icon name='calendar' size={15} color='black' />
                            <p>{moment(tag.dueDate).format('M-DD-YYYY')}</p>
                          </div>
                          : null
                      }

                      {
                        tag.reason
                          ? <div className='info-bit'>
                            <Icon name='calendar-text' size={15} color='black' />
                            <p>{tag.reason}</p>
                          </div>
                          : null
                      }
                    </div>
                    : null
                }

                { // @ts-ignore
                  isp && isp.tags && isp.tags.length
                    ? <div className='info-section'>
                      <div className='tag-wrapper'>
                        { // @ts-ignore
                          isp.tags.map(tag => {
                            return (
                              <div className='tag' onClick={(e) => {
                                e.stopPropagation();

                                // @ts-ignore
                                this.props.setActiveItem(isp, 'tag', tag);
                              }}>
                                <p>{tag.name}</p>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                    : null
                }

                {
                  profile
                    ? <div className='info-section' style={{ minHeight: '150px' }}>
                      <div className='info-header'>
                        <h3>Daily Activity</h3>
                      </div>

                      <DownloadHistory
                        dates={this.state.downloadDates}
                        operationId={isp?.firebaseId ?? 'Not downloaded yet'} 
                      />
                    </div>
                    : null
                }

                {
                  this.state.notes
                    ? <div className='info-section' style={{ minHeight: '150px' }}>
                      <div className='info-header'>
                        <h3>Notes</h3>
                      </div>

                      <div className='notes-wrapper'>
                        {
                          this.state.notes
                            .sort((a, b) => {
                              const aMom = moment(a.createdAt);
                              const bMom = moment(b.createdAt);

                              if (aMom.isBefore(bMom)) return 1;
                              if (bMom.isBefore(aMom)) return -1;

                              return 0;
                            })
                            .map(item => {
                              return (
                                <Note
                                  note={item}
                                />
                              )
                            })
                        }
                      </div>
                    </div>
                    : null
                }

                {
                  !isp || !Object.keys(isp).length
                    ? <div className='info-section' style={{ position: 'relative' }}>
                      <div className='info-header'>
                        <h3>Associate New ISP</h3>
                      </div>

                      <div style={{ padding: '5px' }}>
                        <p style={{ fontWeight: 300, fontSize: '11px' }}>Search to find this ISP. If you can't find them, create a new ISP record.</p>
                      </div>

                      {
                        !this.state.selectedISP
                          ? <div className='search'>
                            <SearchInput
                              value={searchText}
                              onChange={this.onChange}
                              placeholder={'ISP Name'}
                            />

                            {
                              searchText && this.state.results
                                ? <SearchResults
                                  style={{ maxHeight: '250px', overflowY: 'scroll' }}
                                  searching={this.state.searching}>
                                  {
                                    searchText
                                      ? (() => {
                                        const { isps = [] } = this.state.results;
                                        const searchResults = [];

                                        for (const isp of isps) {
                                          // @ts-ignore
                                          const profile = liveISPList[isp.firebaseId || null]

                                          searchResults.push(
                                            <IspEntry
                                              // @ts-ignore
                                              key={`isp ${isp.id}`}
                                              isp={isp}
                                              onClick={() => this.selectISP(isp)}
                                              profile={profile}
                                            />
                                          );
                                        }

                                        return searchResults.length ? searchResults : null;
                                      })()
                                      : [
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px' }}>
                                          <p>Search to find a match to this new active ISP.</p>
                                        </div>
                                      ]
                                  }
                                </SearchResults>
                                : null
                            }
                          </div>
                          : this.state.selectedISP
                            ? <IspEntry
                              inactive
                              isp={this.state.selectedISP}
                              onClick={() => {}}
                              // @ts-ignore
                              profile={liveISPList[this.state.selectedISP.firebaseId || null]}>
                              {// @ts-ignore
                                !this.state.selectedISP.firebaseId
                                  ? <div
                                    className='assoc-btn clickable' onClick={this.associateISP}>
                                    <p style={{ color: 'white' }}>Associate</p>
                                  </div>
                                  : null
                              }

                              {
                                !this.state.associationMade
                                  ? <div
                                    className='assoc-btn2 clickable'
                                    onClick={() => {
                                      this.setState({ selectedISP: null, searchText: '', results: null });
                                    }}>
                                    <p>Search Again</p>
                                  </div>
                                  : <div className='assoc-btn2'>
                                    <p>Association Completed</p>
                                  </div>
                              }
                            </IspEntry>
                            : null
                      }
                    </div>
                    : null
                }
              </div>
            </div>

            <div className='options'>
              <div className='options-header'>
                <h3>Item Actions</h3>
              </div>

              {
                isp && isp.id
                  ? <div className='link-wrapper'>
                    <ConsoleLink
                      to={`isps/${isp.id}`}
                      text='Go to ISP page'
                    />
                  </div>
                  : null
              }

              {
                list && isp && isp.id
                  ? <div className='action' onClick={() => this.deleteItem()}>
                    <Icon name='close' size={15} color='black' />
                    <p>Remove from list: {list.name}</p>
                  </div>
                  : null
              }

              {
                profile && profile.ownerUID
                  ? <div className='link-wrapper'>
                    <FirebaseTextLink
                      path={`Users/${profile.ownerUID}`}
                      text='Owner'
                    />
                  </div>
                  : null
              }

              {
                profile && profile.ISPID
                  ? <div className='link-wrapper'>
                    <FirebaseTextLink
                      path={`ISP/${profile.ISPID}/Profile`}
                      text='ISP Profile'
                    />
                  </div>
                  : null
              }

              {
                subscription
                  ? <div style={{ margin: '0 20px' }}>
                    <StripeLink
                      type='sub'
                      id={subscription.id}
                      showTitle
                    />
                  </div>
                  : null
              }

              {
                subscription && subscription.customer
                  ? <div style={{ margin: '0 20px' }}>
                    <StripeLink
                      type='cus'
                      // @ts-ignore
                      id={subscription.customer.id}
                      showTitle
                    />
                  </div>
                  : null
              }
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

function mapStateToProps(state: RootState) {
  return {
    liveISPList: selectors.liveIspList.selectLiveIspList(state),
    collections: selectors.salesCampaignCollections.selectSalesCampaignCollections(state),
    featureFlags: selectors.baseSelectors.selectFeatureFlags(state),
  }
}

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ISPModal);
