import React from 'react';

import './ListOptions.css';

export default function ListOptions (props) {
  return (
    <div className='SalesListOptions'>
      <div className='title'>
        <h3>List Actions</h3>
      </div>

      <div className='list'>
        <div className='option' onClick={() => props.deleteList(props.list.id)}>
          <p>Delete List...</p>
        </div>
      </div>
    </div>
  );
}
