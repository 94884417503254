import React from "react";
import usePermissions from "@components/Permissions/usePermissions";

export enum PermissionSetKey {
  DASHBOARD = 'dashboard',
  ISPS_DASHBOARD = 'isps-dashboard',
  ISP = 'isp',
  STATION = 'station',
  STATIONS_DASH = 'stations-dash',
  USERS_DASH = 'users-dash',
  PIPELINE = 'pipeline',
  SALES_LISTS = 'sales-lists',
  SUBSCRIPTIONS = 'subscriptions',
  SIGNUPS = 'signups',
  KNOWLEDGE = 'knowledge',
  GHOST_ACCOUNTS = 'ghost-accounts',
  FEATURE_FLAGS = 'feature-flags',
  SERVICE_STATUS = 'service-status',
  SERVICE_MANAGEMENT = 'service-management',
}
type HocProps = {
  permissionsSetKey: PermissionSetKey;
};

type Props<P extends {}> = HocProps & P;

export default function withPermissions<P extends object>(
  WrappedComponent: React.ComponentType<P>,
  shouldRedirect?: boolean
) {
  return function PermissionsWrapper(props: Props<P>) {
    const { loading, hasPermission } = usePermissions(
      props.permissionsSetKey,
      !!shouldRedirect
    );

    if (hasPermission && !loading) return <WrappedComponent {...props} />;
    else return null;
  };
}

